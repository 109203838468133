export const environment = {
  apiUrl: 'https://api.base.28one-dev.com',
  ENV: 'dev',
  sentry: {"enable":false,"enableReplayIntegration":false,"enableBrowserTracingIntegration":false},
  dataName: 'soft',
  clientName: 'try',
  enableReset: true,
  production: false,
  uiClienId: 'a2cb7ab5-5b7a-4d43-8dcb-c364369697a8',
  redirectUrl: 'https://try.28one-dev.com',
  navBar: {
    leftImage: 'assets/svg/logo-dark.svg',
    leftImageHeight: 30,
    menuSetting: '[{"name":"home","default":true,"page-name":"ReportSection578191c5ceaa5ac0fec3","report-id":"1b414d40-fef0-4e72-914a-9827293011de"},{"name":"management report","default":false,"page-name":"ReportSection08cd1c51b0ba529b769c","report-id":"1b414d40-fef0-4e72-914a-9827293011de"},{"name":"inspection","default":false},{"name":"request inspection","default":false}]',
    roleFilter: '{}',
    showAdmin: true,
    asDropdown: false,
  },
  sideBar: '{"inspection-plan-title":"AQL Inspection Plan","inspections-icon":"t-shirt.svg","show-po-selection":false,"show-packaging":false,"show-downloads":false}',
  import: '{"select-group":false,"title":"UPLOAD PRODUCT READY TO INSPECT","select-centre":false,"select-inspection-level":true,"select-inspection-type":true}',
  uploadSizeChart: '{"add-title":"ADD PRODUCT","need-upload":true,"title":"PRODUCT DETAILS","delete-title":"DELETE PRODUCT","product-code-title":"Style Code"}',
  clientInspection: '{"po-number":"PO Number","consol-filter":"Consol. Centre","table":[{"name":"Insp. Date","case":"name","key":"inspection_date"},{"name":"Loc.","type":"number","case":"normal","key":"UDC_id"},{"name":"Insp.","type":"number","case":"normal","key":"inspection_number"},{"name":"Supplier Name","case":"name","key":"supplier_name"},{"name":"Season","case":"normal","key":"season_code"},{"name":"PO Numbers","case":"normal","key":"client_pos"},{"name":"Style","case":"normal","key":"style_code"},{"name":"Description","case":"name","key":"style_name"},{"name":"Colour","case":"name","key":"colour"},{"name":"Result","case":"result","key":"result"},{"name":"Full Report","type":"pdf","case":"file"},{"name":"Summary Report","type":"pdf","case":"summary_file"},{"name":"Version History","case":"report_versions"}],"date-range-minus":10,"department-list":"","division-filter":"","colour-filter":"Colour","result-filter":"Result"}',
  selectInspection: '{"select-centre":false,"show-open-order-filter":false,"date-range-plus":0,"table":[{"name":"Arrived","case":"name","key":"arrived_consol_date"},{"name":"Size Chart","type":"number","case":"normal","key":"size_chart_YN"},{"name":"Insp.","type":"number","case":"normal","key":"inspection_number"},{"name":"Product","case":"normal","key":"style_code"},{"name":"TOT. QTY.","type":"number","case":"normal","key":"order_quantity"},{"name":"AQL","case":"check","key":"select_for_AQL"},{"name":"Inspection Date","case":"select_date","key":"inspection_date"},{"name":"Supplier Name","case":"name","key":"supplier_name"},{"name":"PO Numbers","case":"normal","key":"client_pos","empty":true},{"name":"Action","case":"insp-action"}],"date-title":"Ready Date","date-range-minus":1}',
  aqlPlan: '{"date-range-plus":0,"table":[{"name":"AQL","show":false,"case":"select_unselect","key":"qcdata_select_for_AQL"},{"name":"Insp. Date","show":true,"case":"name","key":"inspection_date"},{"name":"Loc.","show":true,"type":"number","case":"normal","key":"UDC_id"},{"name":"Insp.","show":true,"type":"number","case":"normal","key":"inspection_number"},{"name":"Supplier Name","show":true,"case":"name","key":"supplier_name"},{"name":"Season","show":true,"case":"normal","key":"season_code"},{"name":"PO Numbers","show":true,"case":"normal","key":"client_pos"},{"name":"STYLE","show":true,"case":"normal","key":"style_code"},{"name":"Description","show":true,"case":"name","key":"style_name"},{"name":"Colour","show":true,"case":"name","key":"colour"},{"name":"PO QTY.","show":true,"type":"number","case":"normal","key":"order_quantity"},{"name":"Level","show":true,"type":"number","case":"level","key":"sample_level"},{"name":"INSP. Qty.","show":true,"type":"number","case":"normal","key":"inspection_quantity"},{"name":"SUB.","show":false,"type":"number","case":"normal","key":"inspection_number"},{"name":"AQL Maj<","show":true,"type":"number","case":"normal","key":"allowed_defects_maj"},{"name":"AQL Min<","show":true,"type":"number","case":"normal","key":"allowed_defects_min"},{"name":"Tot. Defects","show":true,"type":"number","case":"normal","key":"count_defects"},{"name":"Current Status","show":true,"case":"normal","key":"status_EN"},{"name":"Result","show":true,"case":"result","key":"result"},{"name":"Inspector Name","show":true,"case":"normal","key":"Inspector"},{"name":"Customer","show":false,"case":"normal","key":"customer_name"}],"select-centre":false,"show-color":true,"title":"AQL Inspection Plan","show-po-number":true,"date-range-minus":1,"show-petek-number":false,"style-code":"Style Code","show-inspection-type":false,"status-filter":"Status"}',
  aqlReport: '{"show-close-btn":false,"show-max-allowed":true,"info-fields":[{"field":"style_code","title":"Product Code"},{"field":"style_name","title":"Product Name"},{"field":"colour","title":"Colour"},{"field":"supplier_name","title":"Supplier"},{"field":"PO_Nos","title":"PO Number"},{"field":"Order_Size","title":"Order Size"},{"field":"inspection_number","title":"Submission"},{"field":"sample_level","title":"Inspection Level"},{"field":"inspection_quantity","title":"Sample Qty."}],"show-result":true,"show-summary-inspection-report":true,"title":"AQL Inspection Report","major":"Major","critical":"Critical","show-centre":false,"show-inspection-date":false,"show-customer-name":false,"minor":"Minor","show-inspection-type":false,"show-inspection-id":true}',
  mainPage: {"redirect-urls":[]},
};